.card-banner {
    padding-top: 3rem;
    .cell {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        .card {
            border-radius: 10px;
        }
    }
    &-3col {
        .card {
            max-width:20rem ;
        }
    }
}