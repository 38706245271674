#main-content {

    .slick-carousel-wrapper {
        margin: 0;

        button {
            // @include button-no-action;
            z-index: 30;
            width: 2rem;
            height: 2rem;

            &:focus {
                &::before {
                    color: $green;
                }
            }

            &:hover {
                text-decoration: none;
            }

            &::before {
                color: $dark-gray;
                font-size: 2rem;
            }

            &.slick-prev {
                left: 0;
                margin-left: .25rem;
            }

            &.slick-next {
                right: 0;
                margin-right: .25rem;
            }
        }

        .slick-dots li button {
            &:focus {
                outline: 2px solid $red;
            }
        }

        .slick-carousel {
            max-height: 35rem;
        }
    }

    .slick-btn {

        z-index: 40;
        position: relative;
        .btn-wrapper {
            position: absolute;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding-right: .5rem;

            button {
                margin-top: .5rem;
                &:first-child {
                    margin-right: .5rem;
                }
            }
        }
    }
}