#main-content {
    // 画像の丸枠
img.circle {
    height: 300px;
    width: 300px;
    object-fit: cover;
    border-radius: 50%;
}
// 見出しの装飾カッコ
.deco-brackets {
    position: relative;
    padding-left: .5em!important;
    &::before,
    &::after {
        position: absolute;
    }
    &::before {
        content: "\3008";
        left: 0;
    }
    &::after {
        content: "\3009";
    }
}
}
// マーカー
strong.marker {
    font-weight: normal;
    background: linear-gradient(transparent 60%, #ffff66 60%);
    &.orange {
        background: linear-gradient(transparent 60%, #ffa166 60%);
        
    }
}