@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

header {
  position: relative;

  #page-top {
    min-height: 8rem;
    padding-bottom: 0;

    background-image: url(#{$path}assets/img/common/header-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
}