* {
    font-feature-settings: "palt"1;
}

#main-content {

    .contents-wrapper {

        h2,
        h3,
        h4,
        h5 {
            font-family: tbchibirgothicplusk-pro, sans-serif;
            font-style: normal;
            letter-spacing: 0.1em;
            font-weight: bold;
        }

        &#top-page {
            h2 {
                @include breakpoint(medium) {
                    font-size: 2.7rem;
                }

                @include breakpoint(small only) {
                    font-size: 1.8rem;
                }
            }
        }
        small {
            color: #696969;
        }
        section {
            @include breakpoint (medium) {
                h2 {
                    margin-bottom: 2rem;

                }

                h3 {
                    margin-bottom: 1.8rem;
                }

                h4 {
                    margin-bottom: 1.5rem;
                }

                h5 {
                    margin-bottom: 1.2rem;
                }

                p {
                    line-height: 1.8;
                    margin-bottom: 1rem;
                }
            }
        }
    }

    // 装飾
    .mark-wave {
        text-decoration: wavy underline $green;
    }

    .mark-underline-green {
        text-decoration: double underline $green;
    }

    .mark-underline-red {
        text-decoration: underline $red;
    }

    .border-blue {
        background: linear-gradient(transparent 70%, $light-blue 80%);
    }

    section .callout {
        h2 {
            background-color: $green;
            padding: .1em 1em;
            color: $white;
            position: relative;

            &::before,
            &::after {
                content: "";
                position: absolute;
                display: block;
                height: 100%;
                top: 0;
            }

            &::before {
                border-left: 10px double $white;
                left: 5px;
            }

            &::after {
                border-right: 10px double $white;
                right: 5px;
            }
            span.subtitle {
                font-size: 1.4rem;
                display: block;
            }
        }

        h3 {
            border-bottom: 2px solid $green;
            padding-left: .25em;
        }

        h4 {
            border-left: 10px solid $green;
            padding-left: .5rem;
            padding-bottom: .2rem;
        }

        p {
            padding-left: .8em;
            padding-right: .8em;
        }

        ol,
        ul {
            padding-left: 1.5em;
        }
    }
}
// Adobe Typekit読み込み対策
.wf-loading {
    opacity: 0;
}
.bold {
    font-weight: bold;
}