.thumbnail {
    // height: 100%;  
    @include breakpoint(small only){
        display: block;
        text-align: center;
    }
    img {
        max-width: 100%;
        height: 100%;
        width: 100%;
        @include breakpoint(small only){
            display: inline-block;
        }
    }
    &.no-border {
        box-shadow: none;
    }
}