.callout {
    border-radius: 10px;
    @include breakpoint(medium){
        margin-bottom: 3rem;
    }
    &.primary {
        background-color: #E2F1FD;
    }
    &.alert {
        background-color: #F9EEEB;
    }
}