@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }
}

body {
  overflow-x: hidden;

  #main-content {
    background-image: url(#{$path}assets/img/common/bg_pattern.png);
    background-size: 200px;
    background-repeat: repeat;
    background-color: rgba(255, 255, 224, 0.85);
    background-blend-mode: lighten;
    flex-grow: 1;
    padding-bottom: 5rem;

    a {
      color: $link-blue;
      ;
      text-decoration: underline;

      &:hover {
        color: $link-hover-blue;
      }
    }
  }

}