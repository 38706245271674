// Google Site Search

.cse .gsc-control-cse, .gsc-control-cse {
  background-color: transparent!important;
  border: none!important;
  padding: 0!important;
}
form.gsc-search-box {
  margin: 0!important;
  width: 240px!important;
  table {
    margin-bottom: 0!important;
    overflow:hidden !important;
      tbody {
                background-color: transparent;
                border: none;
        td.gsc-input {
          padding-right:0!important;
          .gsc-input-box {
            border-radius: 10px 0 0 10px;
            padding-top: 1px;
            padding-bottom: 0.5px;
            .gsib_a {
              padding:0!important;
              input {
                width: 100%!important;
                border-radius: 10px 0 0 10px;
                padding: 10px 0 10px 10px!important;
                height: 1.9rem!important;
              }
            }
            .gsib_b {
              padding: 0!important;
              .gsst_b {
                padding: 0!important;
                a {
                  padding:inherit ;
                }
              }
            }
          }
      }
      td.gsc-search-button {
        border-radius: 0 10px 10px 0!important;
        padding-left: 0!important;
        button {
          padding: 10.5px 12px!important;
          margin: 0!important;
          border: none!important;
          border-radius: 0 10px 10px 0;
          cursor: pointer;
          &:hover {
            background-color: lighten($color: #333, $amount: 15%);
          }
        }
      }
    }
  }
}

// レイアウト
.search-form-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}