#main-content {
    .kome {
        list-style-type: none;
        margin-left: .5rem;

        li {
            padding-left: 1rem;
            position: relative;

            &::before {
                position: absolute;
                content: "※";
                top: 0;
                left: 0;
                line-height: 1;
                font-size: 120%;
                color: $red;
            }
        }
    }

    .icon-list {
        margin-left: 2.5rem;
        list-style-type: none;

        li {
            margin-bottom: 1rem;
            text-indent: -0.8rem;
            margin-left: 1rem;

            >i {
                &:first-child {
                    margin-right: .5rem;
                }

                &:not(:first-child) {
                    text-indent: 0;
                }
            }

            a+span {
                position: relative;

                >i {
                    position: absolute;
                    top: 0;
                    left: 1rem;
                }

                margin-right: 1rem;
            }
        }
    }

    // dl インデント調整
    .dl-indent {
        padding-left: 1.5em;

        @include breakpoint(small only) {
            padding-left: 1em;
        }

        dd {
            margin-left: 3em;

            @include breakpoint(small only) {
                margin-left: 1em;
            }
        }
    }

    // dl 横並び
    .dl-horizontal {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-left: 2rem;

        dt {
            width: 20%;
        }

        dd {
            width: 80%;
            line-height: 1.8;
        }

        @include breakpoint (small only) {

            dt,
            dd {
                width: 100%;
            }

            dd {
                padding-left: 2rem;
            }
        }
    }
// dl インデント
.dl-indent {
    padding-left: 1.5em;

    @include breakpoint(small only) {
        padding-left: 1em;
    }

    dd {
        margin-left: 3em;

        @include breakpoint(small only) {
            margin-left: 1em;
        }
    }
}
    // New Release!
    .indent-line {
        list-style: none;
        margin-left: 4.5rem;
        padding-left: 0;

        >li {
            position: relative;

            &::before {
                position: absolute;
                content: "\2e3b";
                display: block;
                left: -3.5rem;
            }
        }
    }

    // 丸数字
    .circular-num {
        counter-reset: my-counter;
        list-style: none;
        padding-left: 0;

        >li {
            font-size: 1rem;
            line-height: 1.5;
            padding-left: 1.5rem;
            position: relative;

            &::before {
                content: counter(my-counter);
                counter-increment: my-counter;
                background-color: $white;
                border: 1px solid;
                border-radius: 50%;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 1.2rem;
                width: 1.2rem;
                color: $black;
                font-size: 80%;
                line-height: 1;
                position: absolute;
                top: .15rem;
                left: 0;
            }
        }
    }

    // チェックリスト
    .check-list {
        list-style: none;
        margin-left: 2.5rem;

        li {
            line-height: 2;

            &::before {
                display: inline-block;
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                font-size: 1.4rem;
                color: $blue;
                content: "\f058";
                margin-right: .5rem;
                font-size: 100%;
            }
        }
    }

    // 無印
    .no-deco {
        list-style: none;
    }

    //リンクリスト
    .link-list {
        list-style: none;

        li {
            &::before {
                content: "\f138";
                font-family: "Font Awesome 5 Free";
                font-weight: bold;
                color: $green;
                margin-right: .25rem;
            }
        }
    }

    // 大丸リスト
    .list-circle {
        list-style: none;

        li {
            padding-left: 1.25em;
            position: relative;

            &::before {
                position: absolute;
                content: "\25ef";
                left: 0;
                font-weight: bold;
            }
        }
    }
}

// イベントページ　カテゴリートップ　グリッドメニュー
#event-index {
    .grid-thumbs-list {
        list-style: none;

        a {
            text-decoration: none;
            .card {
            @include shadow($prototype-box-shadow);
            background-color: $green;
            img {
                width: 320px;
                height: 180px;
                object-fit: cover;
                object-position: top;

            }

            h3 {
                font-size: 1rem;
                font-weight: normal;
                margin-top: .5rem;
                margin-bottom: 0 !important;
                border-bottom: none !important;
                color: $white;
            }

            p {
                font-size: 90%;
                color: $white;
                text-align: right;
                // font-weight: bold;
            }
        }
        }

    }
    .event-list {
        align-items: center;
        dt, dd {
            margin-bottom: .5rem;
        }
        dt {
            font-weight: normal;
        }

    }

}
