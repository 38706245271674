// SP mode
.title-bar.sp-title-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $green;
    padding: 0 .5rem;
    .menu-icon {
        position: relative;
        span {
            position: absolute;
            left: 1.8rem;
            top: 0;
            color: $white;
            font-weight: bold;
        }
    }
    .top-bar-right {
        img.logo {
            height: 4rem;
            padding: .25rem 2rem .25rem 0;
        }
    }
}

// PC mode
#page-top {
    padding: 1rem .5rem 1rem 1rem;

    .title-logo {
        a {
            .logo {
                max-width: 15rem;

                @include breakpoint(medium only) {
                    max-width: 10rem;
                }
            }
        }
    }

    .header-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
}