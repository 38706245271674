// 新着情報
.whats_new {
    border: 1px solid #bfbfbf;
    border-radius: 5px;
    padding: 0!important;
    background-color: $gray;
    @include shadow($prototype-box-shadow);
    .whats_new_caption {
        background-color: $blue;
        padding: 0.25rem 1rem;
        h2 {
            font-size: 1.8rem;
            margin-bottom: 0;
            color: #fff;
        }
    }
    .whats_new_data {
        background-color: $white;
        max-height: 20rem;
        overflow: scroll;
        ul.head_news {
            list-style: none;
            margin: 0;
            padding-inline-start: 0;
            li {
                display: flex;
                flex-flow: row wrap;
                &:nth-of-type(odd){
                    background-color:$white;
                    &:hover {
                        background-color:#fff;
                    }
                }
                &:nth-of-type(even){
                    background-color: rgba($color: #B8DBEA, $alpha: .4);
                    &:hover {
                        background-color: darken($color: #E1F0F5, $amount: 5%);
                    }
                }
                a {
                    display: flex;
                    flex-direction: row;
                    padding-left: 0;
                    width: 100%;
                    color: #1c6a97;
                    &:hover,
                    &:focus {
                        color: darken($color: #1c6a97, $amount: 10%);
                    }
                    &::before {
                        content: none;
                    }
                    span {
                    &:nth-of-type(1){
                        width: 30%;
                        padding-top: .25rem;
                        padding-left: 1rem;
                    }
                    &:nth-of-type(2){
                        width: 70%;
                        padding-top: .25rem;
                        padding-bottom: .25rem;
                    }
                }
            }
        }
    }
    }
}
// トップページ新着更新のバッジ
.top-whatsnew-blackboard .handwriting-list {
    .label {
        margin-left: .25rem;
        padding-left: .5rem;
        padding-right: .5rem;
        border-radius: 5px;
        font-weight: bold;
        font-size: 1.2rem;
// <span class="label label__new">NEW</span>
    &__new {
        background-color: $red;
        color: $white;
    }
// <span class="label label__close">終了</span>
    &__close {
        background-color: $blue;
    }
// <span class="label label__cancel">中止</span>
    &__cancel {
        background-color: $light-gray;
        // border: 4px solid $red;
        // box-sizing: border-box;
        color: $red;
    }
    }
}