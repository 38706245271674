.table-scroll {
    white-space: nowrap !important;
}

#main-content {
    table {
        thead {
            background-color: lighten($color: $blue, $amount: 30);

            th {
                font-weight: normal;
                text-align: center;
                padding: .25rem;
                border: 1px solid $light-gray;
            }
        }

        tbody {
            background-color: $white;

            tr:nth-child(even) {
                background-color: lighten($color: $light-blue, $amount: 5);

                & th {
                    background-color: darken($color: $light-blue, $amount: 1);
                }
            }

            tr:nth-child(odd) {
                background-color: $light-gray;

                & th {
                    background-color: darken($color: $light-yellow, $amount: 1);
                }
            }

            tr th {
                background-color: darken($color: $white, $amount: 5);
            }

            tr td {
                border-right: 1px solid $gray;

                &:last-of-type {
                    border-right: none;
                }
            }

            th.tategaki,
            td.tategaki {
                -ms-writing-mode: tb-rl;
                writing-mode: vertical-rl;
                min-height: 1rem;
            }
        }

        &.time-table {
            tbody {
                border: 1px solid $dark-gray;

                th[scope=row] {
                    background-color: $light-blue;
                }

                th[scope=col] {
                    background-color: $link-hover-blue;
                    color: $white;
                }

                td {
                    background-color: $white;
                    border: 1px solid $dark-gray;
                }
            }
        }
    }

    .tabs-panel & {
        table-layout: fixed;
        max-width: 800px;

        td {
            overflow-wrap: break-word;
        }
    }
}
_::-webkit-full-page-media, _:future, :root #main-content table tbody th.tategaki,
_::-webkit-full-page-media, _:future, :root #main-content table tbody td.tategaki {
                -ms-writing-mode: horizontal-tb;
                writing-mode:horizontal-tb;
    }