#main-content {
    button, .button {
    color: $white;
    text-decoration-line: none;
    border-radius: 5px;
    // @include shadow($prototype-box-shadow);

    &:hover {
        color: $white;
    }
}
}
// @mixin button-no-action {
//     box-shadow: none;
//     &:hover {
//         transform: none!important;
//         text-decoration: none;
//         color: inherit;
//     }
// }
.btn-push {
    @include shadow($prototype-box-shadow);
    &:hover {
        transform: translate3d(1px, 2px, 0);
    }
}