.header-menu .subNav {
    li {
        background-color: rgba(255,255,255,0.75);
        margin-right: .25rem;
        border-radius: 5px;
        a {
            padding: .5rem .25rem;
            text-decoration: underline;
            
        }
    }
}