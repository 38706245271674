    // ヘッダー部分　忍者
    @keyframes slideDown {
        0% {
            transform: translateY(-100%);
        }

        100% {
            transform: translateY(0);
        }
    }

    header {
        .animation-wrapper {
            position: relative;

            img {
                max-height: 6rem;
                z-index: 50;
                position: absolute;
                top: 1.5rem;
                left: 25%;
                -webkit-animation: 1.5s ease-out 0s 1 slideDown;
                animation: 1.5s ease-out 0s 1 slideDown;
            }
        }
    }
@keyframes jiggle {
    0% {transform: translate(0px, 0px) rotateZ(0deg)}
    25% {transform: translate(2px, 2px) rotateZ(1deg)}
    50% {transform: translate(0px, 2px) rotateZ(0deg)}
    75% {transform: translate(2px, 0px) rotateZ(-1deg)}
    100% {transform: translate(0px, 0px) rotateZ(0deg)}
}
    .jiggle {
    display: inline-block;
    animation: jiggle 1.2s  infinite;
}