#main-content {
    form {
        label {
            padding-left: .5rem;
            sup {
                color: $red;
                font-size: 120%;
                font-weight: bold;
            }
        }
        input {
            &::placeholder {
                color: $dark-gray;
            }
        }
    }
}