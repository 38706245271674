#main-content {
    .title-header-wrapper {
        background-color: $light-blue;
        background-image: url(#{$path}assets/img/common/bg_pattern.png);
        background-repeat: repeat;
        background-size: 200px;
        background-blend-mode: overlay;

        @include breakpoint(medium) {
            min-height: 15rem;
        }

        @include breakpoint(small) {
            height: 10rem;
        }

        .title-header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;

            .title-header-content {
                background-color: $white;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                border-radius: 10px;
                border: 20px double $green;
                padding: 1rem 8rem;

                @include breakpoint(medium only) {
                    padding: 0 6rem;
                    border: 15px double $green;
                }

                @include breakpoint(small only) {
                    padding: 0 4rem;
                    border: 10px double $green;
                    margin: 0 .5rem;
                }

                // 矢口家のアイコン
                &::before,
                &::after {
                    position: absolute;
                    display: inline-block;
                    content: "";
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 8rem;
                    height: 8rem;
                    top: 0;

                    @include breakpoint(medium only) {
                        width: 6rem;
                        height: 6rem;
                    }

                    @include breakpoint(small only) {
                        width: 4rem;
                        height: 4rem;
                    }
                }

                &::before {
                    left: 0;
                    top: 0;

                }

                &::after {
                    right: 0;
                    bottom: 0;
                }

                &.yaguchi-pattern1::before {
                    background-image: url(#{$path}assets/img/common/yaguchi-pattern-sp-a1.png);
                }

                &.yaguchi-pattern1::after {
                    background-image: url(#{$path}assets/img/common/yaguchi-pattern-sp-a2.png);
                }

                &.yaguchi-pattern2::before {
                    background-image: url(#{$path}assets/img/common/yaguchi-pattern-sp-b1.png);
                }

                &.yaguchi-pattern2::after {
                    background-image: url(#{$path}assets/img/common/yaguchi-pattern-sp-b2.png);
                }

                &.yaguchi-pattern3::before {
                    background-image: url(#{$path}assets/img/common/yaguchi-pattern-sp-c1.png);
                }

                &.yaguchi-pattern3::after {
                    background-image: url(#{$path}assets/img/common/yaguchi-pattern-sp-c2.png);
                }
                // テキスト装飾
                h1 {
                    text-align: center;
                    font-family: fot-seurat-pron, sans-serif;
                    font-weight: 600;
                    font-style: normal;
                    font-size: 3rem;
                    line-height: 1.2;

                    @include breakpoint (medium only) {
                        font-size: 2.8rem;
                    }

                    @include breakpoint(small only) {
                        font-size: 1.6rem;
                        background-color: transparent;
                    }
                }

                p {
                    text-align: center;
                    font-size: 1.2rem;
                    @include breakpoint(small only) {
                        font-size: 1rem;
                    }
                }
            }
        }
    }

}