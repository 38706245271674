#main-content {
    .tab-wrapper {
        margin-bottom: 3rem;
        .tabs {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            border: none;
            padding-left: 0 !important;

            &::before,
            &::after {
                display: none;
            }

            .tabs-title {
                background-color: $yellow;
                width: 100%;
                text-align: center;
                border-left: 1px solid $dark-gray;
                border-top: 1px solid $dark-gray;
                border-bottom: 1px solid $dark-gray;

                &:first-of-type {
                    border-radius: 15px 0 0 0;

                }

                &:last-of-type {
                    border-radius: 0 15px 0 0;
                    border-right: 1px solid $dark-gray;
                }

                &.is-active {
                    background-color: $white;
                    border-bottom: none;
                }

                a {
                    font-size: 1.2rem;
                    color: $black;
                    font-style: italic;
                    text-decoration: none;
                    border-radius: 15px;
                    margin: .25rem 0;

                    &:hover {
                        background-color: inherit;
                        cursor: pointer;
                    }
                }

                &.is-active a {
                    color: $black;
                    font-weight: bold;
                    font-style: normal;
                    text-decoration: none;
                    background-color: transparent;
                }

                &:hover {
                    color: $black;
                }
            }
        }

        .tabs-content {
            border: none;

            .tabs-panel {
                background-color: $white;
                border: 1px solid $dark-gray;
                border-top: none;
                border-radius: 0 0 10px 10px;

                thead,
                tbody {
                    border: none;
                }

                thead {
                    background-color: transparent;

                    th {
                        text-align: center;
                        color: $dark-gray;
                        font-weight: normal;
                        border: none;
                    }
                }

                tr {
                    border-bottom: 1px solid $gray;

                    &:nth-child(even) {
                        background-color: #DDEBF6;
                    }
                }
            }
        }

    }
}