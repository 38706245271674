#main-content {

    // トップページバナー
    .site-introduction {
        .card {
            .card-image-holder {
                background-color: $light-blue;
                padding: 1rem .8rem;

                img {
                    max-height: 12rem;
                }
            }
        }
    }

    // トップページバナー　関連リンク
    .top-banner-link .banner-link .cell {
        padding: 1rem;

        a {
            text-decoration: none;
            .card {
                border-radius: 10px;
                background-color: $light-blue;
                padding: .5rem;
                min-height: 5rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                h3 {
                    font-size: 1.4rem;
                    font-weight: normal;
                    @include breakpoint (medium only){
                        font-size: 1.25rem;
                    }
                }
            }
        }
    }
}


// 活動内容バナー
.activity {
    .card {
        .card-image-holder {
            background-color: rgba($color: $light-green, $alpha: .9);
            background-image: url(#{$path}assets/img/common/bg_pattern_banner.png);
            background-repeat: repeat;
            background-size: 40px;
            background-blend-mode: lighten;
            padding: .8rem;
            text-align: center;

            img {
                max-height: 15rem;
            }
        }
    }
}