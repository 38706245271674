.gNav.vertical.menu > li > ul.is-dropdown-submenu {
 width: 100%;
 background-color: rgba(58,123,42,0.9);
top: 100%;
z-index: 50;
 li {
     border-bottom: 1px solid $light-gray;
     &:last-child {
         border-bottom: none;
     }
     a {
         color: $white;
         &:hover {
            text-decoration: underline;
         }
     }
 }
}