$blue : #094BB5;
$dark-blue : #0846AA;
$link-blue : #035B96;
$link-hover-blue : #0374BF;
$light-blue : #a6cbf2;
$gray : #bebebe;
$light-gray : #F5F5F5;
$dark-gray : #757575;
$red : #e00000;
$green : #007E18;
$dark-green : #004B15;
$light-green : #90f6a8;
$light-yellow : #FFFFE6;
$yellow : #fcf4a3;
$white : #fefefe;
.blue {
    color: $blue;
}
.white {
    color: $white;
}
.red {
    color: $red;
}
// background-color
.bg-gray {
    background-color: $gray;
}
.bg-green {
    background-color: $green;
}
