.contents-wrapper {
    margin: 0 auto;
    padding-top: 1.5rem;

}

.grid-container.full {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

// 学生向けの講師派遣　advisor/advisor-students.html

    section {
        .media-object {
            justify-content: space-around;
        }
    }
// 子要素の左右中央寄せ
.child-align-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
// d-flex-column
.d-flex-column {
    display: flex;
    flex-direction: column;
}
// d-flex-row 
.d-flex-row {
    display: flex;
    flex-direction: row;
}
.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}