body #main-content .contents-wrapper {
        .breadcrumbs {
        margin-left: 1rem;
        li {
            font-size: .9rem;
            &:last-child {
                font-style: italic;
                color: $dark-gray;
                background-color: rgba($color: $white, $alpha: .6);
            }
        }
    }
}