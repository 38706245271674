#main-content {
    .iframe-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        padding-top: 75%;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        
    }
    }
}