.top-eyecatch {
    position: relative;
    margin-bottom: 3rem;

    .top-lead-title {
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 20;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .lead {
            background-color: rgba(255, 255, 255, 0.7);
            border-radius: 15px;
            padding: 1rem;
            color: $blue;
            font-family: tbchibirgothicplusk-pro, sans-serif;
            font-style: normal;
            line-height: 1.05;
            font-weight: bold;

            @include breakpoint(medium) {
                margin: 0 2.5rem;
                padding: .5rem;
                font-size: 5.5rem;
            }

            @include breakpoint(small only) {
                margin: 0 2rem;
                font-size: 2rem;
                padding: .3rem;
            }

            span {
                color: $red;
                font-size: 9rem;

                @include breakpoint(medium only) {
                    font-size: 8rem;
                }

                @include breakpoint(small only) {
                    font-size: 3rem;
                }
            }
        }
    }
}

#top-page {

    // 見出しのデザイン
    h2 {
        margin-top: 4rem;
        margin-bottom: 4rem;

        &:first-of-type {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        span {

            position: relative;

            &::before,
            &::after {
                position: absolute;
                content: "";
                width: 3.5rem;
                height: 3.5rem;
                background-repeat: no-repeat;
                background-size: contain;
                top: 0;
            }

            &::before {
                top: 1rem;
                left: -3.5rem;
            }

            &::after {
                top: .5rem;
            }
        }
    }

    .whats-new h2 span {
        &::before {
            background-image: url(#{$path}assets/img/common/icon-piggybank.png);
        }

        &::after {
            background-image: url(#{$path}assets/img/common/icon-book.png);
        }
    }

    .site-introduction h2 span {
        &::before {
            background-image: url(#{$path}assets/img/common/icon-passbook.png);
        }

        &::after {
            background-image: url(#{$path}assets/img/common/icon-passbook2.png);
        }
    }

    .top-banner-link h2 span {
        &::before {
            background-image: url(#{$path}assets/img/common/icon-bank.png);
        }

        &::after {
            background-image: url(#{$path}assets/img/common/icon-gov.png);
        }
    }

    // 新着情報
    .whats-new {

        img {
            width: 300px;
        }

        .top-whatsnew-blackboard {
            background-image: url(#{$path}assets/img/common/top-whatsnew-blackboard.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: left;
            padding: 5rem 3rem 0 3rem;
            height: 27rem;

            @include breakpoint(medium only) {
                background-position: center;
            }

            @include breakpoint(small only) {
                background-image: none;
                background-color: #618F6C;
                border-radius: 20px;
                height: 20rem;
                padding: 2rem 1rem;
            }

            .handwriting-list {
                list-style: none;
                height: 80%;
                overflow-x: scroll;
                max-width: 50rem;
                padding-right: 2rem;
                margin-left: 0;

                @include breakpoint(medium only) {
                    padding-left: 3rem;
                    width: 90%;
                }

                @include breakpoint(small only) {
                    height: 100%;
                    width: 100%;
                    padding-left: 0;
                    padding-right: 1rem;
                }

                li {
                    font-family: 'HuiFontP29';
                    margin-bottom: .5rem;
                    font-size: 1.2rem;

                    a {
                        color: $white;
                        text-decoration: none;

                        > span {
                            display: inline-block;

                            &:nth-of-type(2) {
                                margin-left: 1rem;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

        }

    }

    // サイト紹介
    .site-introduction {
        .cell {
            padding: 1rem;

            a {
                text-decoration: none;

                .card {
                    @include shadow($prototype-box-shadow);
                    border-radius: 10px;

                    .card-divider {
                        border-radius: 10px 10px 0 0;
                        background-color: #3CB371;
                        justify-content: center;

                        h3 {
                            font-size: 1.5rem;
                        }
                    }

                    .card-section {
                        border-radius: 0 0 10px 10px;
                        background-color: $light-gray;

                        p {
                            color: $link-blue;
                        }
                    }
                }
            }
        }
    }


    // 関連サイト
    .top-banner-link {
        padding-bottom: 3rem;

        h2 {
            margin-bottom: 3rem;
        }

        .banner-link {
            margin-left: 0;
            list-style-type: none;

            li {
                margin-bottom: 1rem;
                text-align: center;
            }
        }
    }
}