@include breakpoint (small only) {
    #offCanvas {
        background-color: $light-blue;
        background-image: url(#{$path}assets/img/common/bg_pattern.png);
        background-size: 150px;
        background-repeat: repeat;
        background-blend-mode: hard-light;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-height: 100vh;
        button.close-button {
            background-color: $white;
            width: 1.5rem;
            height: 1.5rem;
            top: 0.5rem;
            line-height: 1;
            font-size: 1rem;
            color: $black;
            border-radius: 5px;
        }
        p.logo-sp-menu {
            margin-top: 3rem;
            .logo {
                width: 100%;
                padding: .25rem .5rem;
            }

        }
        .gNav {
            li {
                &.is-accordion-submenu-parent > a {
                    &:hover {
                        text-decoration: none;
                    }
                }
                a {
                    color: #0846AA;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .sp-nav-footer {
            background-color: $dark-gray;
            .subNav {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                li {
                    a {
                        color: $white;
                        text-decoration: underline;
                        &:hover {
                            color: $blue;
                        }
                    }
                }
            }
        }
    }
}