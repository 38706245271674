.gNav.menu {
    background-color: $green;
    justify-content: space-around;
    z-index: 50;
    @include breakpoint (small only) {
        background-color: transparent;
    }



       > li {
            text-align: center;
            @include breakpoint (small only) {
                margin-bottom: .5rem;
            }

         >   a {
                color: $white;
                font-weight: bold;
                &:hover,
                &.is-active
                 {
                    text-decoration: underline;
                }
                @include breakpoint (small only) {
                    color: $link-blue;

                    &:hover {
                        color: $link-hover-blue;
                    }
                }
            }
             &.is-dropdown-submenu-parent {
                 > a {
                     &:hover {
                         text-decoration: none;
                         cursor: text;
                     }
                 }
                a::after {
                    color: $white;
                    border-color: $white transparent transparent!important;
                    right: 5px;
                    left: calc(100%)!important;
                    margin-top: -3px;
                }
             }
        }
    }

